export const SECONDS = 1000;

export const MINUTES = 60 * SECONDS;

export const HOURS = 60 * MINUTES;

export const DAYS = 24 * HOURS;

export const WEEKS = 7 * DAYS;

export const YEARS = 365 * DAYS;

export const days = [{
  name: 'Sunday',
  short: 'Sun' },
{
  name: 'Monday',
  short: 'Mon' },
{
  name: 'Tuesday',
  short: 'Tue' },
{
  name: 'Wednesday',
  short: 'Wed' },
{
  name: 'Thursday',
  short: 'Thu' },
{
  name: 'Friday',
  short: 'Fri' },
{
  name: 'Saturday',
  short: 'Sat' }];


export const months = [{
  name: 'January',
  short: 'Jan' },
{
  name: 'February',
  short: 'Feb' },
{
  name: 'March',
  short: 'Mar' },
{
  name: 'April',
  short: 'April' },
{
  name: 'May',
  short: 'May' },
{
  name: 'June',
  short: 'June' },
{
  name: 'July',
  short: 'July' },
{
  name: 'August',
  short: 'Aug' },
{
  name: 'September',
  short: 'Sep' },
{
  name: 'October',
  short: 'Oct' },
{
  name: 'November',
  short: 'Nov' },
{
  name: 'December',
  short: 'Dec' }];